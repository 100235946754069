@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap");

/* CSS variables: */
:root {
  --color-purple: #362836;
  --color-lightGrey: #f4f4f4;
  --color-white: #fff;
}

body {
  margin: 0;
  font-family: "Roboto", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI","Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-purple);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 2rem;
}

h3 {
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 2rem;
}

a {
  margin-top: 1rem;
  text-decoration: none;
  color: var(--color-purple);
}
